/* eslint-disable max-len */
import PropTypes from 'prop-types'

export const Star = ({ style, className, filled }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="50"
    viewBox="0 0 50 50"
    style={{ ...style, fill: filled ? '#933EC5' : undefined }}
    className={className}
  >
    <path
      fillRule="nonzero"
      stroke="#933EC5"
      d="M49.36 19.204a.405.405 0 0 0-.318-.286l-16.415-2.474-.114-.24-7.224-15.179a.39.39 0 0 0-.348-.23.39.39 0 0 0-.348.231l-7.339 15.419-.263.04L.84 18.917a.404.404 0 0 0-.319.286.45.45 0 0 0 .104.447l11.868 11.996-.042.252-2.76 16.683a.445.445 0 0 0 .164.426.36.36 0 0 0 .399.03L24.94 41.03l.24.13 14.446 7.877a.368.368 0 0 0 .4-.03.441.441 0 0 0 .163-.425l-2.8-16.935.179-.181 11.687-11.815a.45.45 0 0 0 .105-.448z"
    />
  </svg>

)

Star.propTypes = {
  style: PropTypes.instanceOf(Object),
  className: PropTypes.string,
  filled: PropTypes.bool
}

Star.defaultProps = {
  style: {},
  className: undefined,
  filled: false
}
