import styled from 'styled-components'
import Colors from '../../constants/colors'

export const GridItemA = styled.a`
  width: 30%;
  margin: 1.56%;
  margin-top: 0px;
  cursor: pointer;
  text-decoration: none;
  color: ${Colors.text};
  font-size: 20px;
  line-height: 30px;
  .starDiv {
    display: flex;
    align-items: center;
    padding-top: 10px;
  }
  .starButton {
    background: none;
    border: none;
    outline: none;
    padding: 0;
    cursor: pointer;
    margin-right: 15px;
  }
  .star {
    width: 30px;
    height: 30px;
    fill: white;
    transition: fill 0.4s ease 0s;
    :hover {
      fill: ${Colors.primary};
    }
  }
  .time {
    font-family: StolzlMedium, serif;
    letter-spacing: 2px;
    @media (max-width: 1000px) {
      font-size: 16px;
    }
  }
  .name {
    transition-duration: 0.2s;
    letter-spacing: 1px;
    @media (max-width: 1000px) {
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0;
    }
  }
  .photoContainer {
    position: relative;
    height: 200px;
    overflow: hidden;
    border-radius: 6px 6px 0 0;
    img {
      position: absolute;
      bottom: -1px;
      left: -1px;
      width: 50%;
    }
    @media (max-width: 1000px) {
      height: 150px;
    }
    @media (max-width: 650px) {
      height: 30vw;
    }
  }
  @media (max-width: 900px) {
    width: 45%;
    margin: 2.5%;
  }
  &:hover {
    .name {
      color: ${Colors.primary};
    }
    .photo {
      transform: scale(1.1);
    }
  }
`

export const GridPhoto = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #000;
  background: url(${props => props.photo
  || (props.isArtist ? '/static/images/empty-artist.svg' : '/static/images/empty-place.svg')})
    no-repeat center center;
  background-size: cover;
  margin-bottom: 10px;
  transition-duration: 0.2s;
`
