import styled from 'styled-components'

export const RecommendedRoutes = styled.div`
  cursor: pointer;
  ${props => `${props.styles ? props.styles : ''}`}
  .routeCard {
      width: 300px;
      display: flex;
      flex-direction: column;
      border-radius: 10px;
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
      height: 240px;
      overflow: hidden;
      @media (max-width: 1000px) {
        ${props => (props.program ? '' : 'height: 150px;')}
      }
      @media (max-width: 370px) {
        width: 250px;
      }
      :hover {
        .cardPhoto {
          transform: scale(1.1);
        }
      }
  }
  .routeLink {
      color: #000;
      text-decoration: none;
  }
  .cardPhotoDiv {
    width: 100%;
    height: 144px;
    position: relative;
    overflow: hidden;
  }
  .cardPhoto {
      position: absolute;
      object-fit: cover;
      display: block;
      height: 100%;
      width: 100%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      transition-duration: 0.2s;
  }
  .duration {
      text-transform: lowercase;
      letter-spacing: normal;
      font-size: 14px;
      text-align: right;
      line-height: 1.57;
      color: #933ec5;
      @media (max-width: 1200px) {
        ${props => (props.program ? '' : 'font-size: 8px; line-height: 1.14;')}
      }
  }
  .starDiv {
      align-items: flex-end;
      margin-right: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 66px;
      @media (max-width: 1200px) {
        margin-right: 7px;
      }
      @media (max-width: 1000px) {
        ${props => (props.program ? '' : 'height: 38px; margin-right: 7px;')}
      }
  }
  .starDiv button {
      border: none;
      background: none;
      outline: none;
      cursor: pointer;
      padding: 0;
      @media (max-width: 1000px) {
        margin-top: 3px;
      }
  }
  .star {
    width: 30px;
    height: 28px;
    fill: white;
    transition: fill .4s ease;
    :hover {
      fill: #933EC5;
    }
    @media (max-width: 1000px) {
      ${props => (props.program ? '' : 'width: 16px; height: 16px;')}
    }
  }
  .from,
  .counts {
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      text-transform: lowercase;
      font-size: 14px;
      line-height: 1.57;
      letter-spacing: normal;
      @media (max-width: 1200px) {
        ${props => (props.program ? '' : 'font-size: 11px; line-height: 2.14;')}
      }
      @media (max-width: 1000px) {
        ${props => (props.program ? '' : 'font-size: 8px; line-height: 1.8;')}
      }
  }
  
  .from {
    text-transform: none;
  }
  .name {
      font-family: StolzlMedium, serif;
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 16px;
      line-height: 1.88;
      letter-spacing: 2px;
      text-transform: uppercase;
      @media (max-width: 1200px) {
        ${props => (props.program ? '' : 'font-size: 11px; line-height: 1.75;')}
      }
      @media (max-width: 1000px) {
        ${props => (props.program ? '' : 'font-size: 8px; line-height: 1.75;')}
      }
  }
  .fromDiv {
      max-width: 60%;
      margin-left: 15px;
      display: flex;
      flex-direction: column;
      @media (max-width: 1200px) {
          margin-left: 7px;
      }
  }
  .routeCardMenu {
      width: 100%;
      align-self: flex-end;
      margin-bottom: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-height: 90px;
      margin-top: 10px;
      @media (max-width: 1000px) {
        margin-bottom: 4px;
        ${props => (props.program ? '' : 'margin-top: 4px; max-height: 40px; height: 100%;')}
      }
  }
`
